import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'package', 'countryPrice', 'totalPrice' ]

  initialize() {
    this.updateCountry()
  }

  updateCountry(skip_warning = false) {
    const packageData = this.packageTarget.selectedOptions[0].dataset
    const options = this.countryPriceTarget.options

    // hide countries which aren't valid for this package
    let num_available = 0
    let last_available = 0
    let current_selection = options.selectedIndex
    let current_country = this.countryPriceTarget.selectedOptions[0].value

    Object.values(options).forEach((option, idx) => {
      // keep the blank option
      if (idx == 0) { option.dataset.disabled = "false"; return }

      const valid = (
        option.dataset.prices
        && option.dataset.regionCode === packageData.regionCode
        && option.dataset.itemType === packageData.itemType
        && option.dataset.offeringId === packageData.offeringId
      )

      if (valid) {
        // available
        option.dataset.disabled = "false"
        num_available += 1
        last_available = idx

      } else {
        // hidden
        option.dataset.disabled = "true"

        if (current_selection == idx && num_available > 1) {
          // with multiple options available, select blank
          current_selection = 0
        }
      }
    })

    // keep current country across different item types (each country appears multiple times in the list)
    Object.values(options).forEach((option, idx) => {
      if (option.dataset.disabled == "false" && current_country == option.value) {
        current_selection = idx
      }
    })

    // Update select
    options.selectedIndex = current_selection

    if (num_available > 0) {
      this.countryPriceTarget.disabled = false

      // ensure disable values can't remain selected
      if (this.countryPriceTarget.selectedOptions[0].dataset.disabled == "true") {
        options.selectedIndex = 0
      }

      if (num_available == 1) {
        // select the only available option
        options.selectedIndex = last_available
      }

      this.updatePrice()
    } else {
      this.countryPriceTarget.disabled = true
    }
  }

  updatePrice() {
    const packageData = this.packageTarget.selectedOptions[0].dataset
    const countryPriceData = this.countryPriceTarget.selectedOptions[0].dataset


    if ("prices" in countryPriceData) {
      const price = Number(JSON.parse(countryPriceData.prices)[packageData.books])

      let event = new CustomEvent('newsub:price', { bubbles: true, detail: { price: price } })
      this.element.dispatchEvent(event)

      if (!this.hasTotalPriceTarget) { return }

      if (price) {
        this.totalPriceTarget.innerHTML = this.totalPriceTarget.dataset.label + `£${price}`
      } else {
        this.totalPriceTarget.innerHTML = this.totalPriceTarget.dataset.label + "n/a"
      }
    }
  }
}
