import { Controller } from "stimulus"
import Rails from '@rails/ujs'
import debounce from "lodash.debounce"

export default class extends Controller {
  static targets = ['results', 'info', 'book']

  initialize() {
    this.submit = debounce(this.submit, 500)
  }

  submit(e) {
    Rails.fire(e.target.closest('form'), 'submit')
  }

  results(e) {
    const [data, status, xhr] = event.detail
    if (data instanceof Node) {
      this.resultsTarget.innerHTML = data.querySelector('.results').innerHTML
    } else {
      console.log('unknown response', data, status)
    }
  }

  select(e) {
    this.picked(e.target.dataset.book)
  }

  pick(e) {
    let id = e.target.dataset.book
    let url = this.data.get('proposeUrl')
    let controller = this

    e.target.closest('.button').classList.add('is-loading')

    let form_data = new FormData()
    form_data.append('book_id', id)

    Rails.ajax({
      url: this.data.get('proposeUrl'),
      type: 'PATCH',
      data: form_data,
      dataType: 'json',
      success: (data) => { controller.picked(id) },
      error: () => { e.target.closest('.button').classList.remove('is-loading') }
    })
  }

  picked(book_id) {
    const book_target = this.bookTargets.find((element) => { return parseInt(element.dataset.id) == parseInt(book_id) })
    let html = ''

    if (book_target) {
      html = book_target.querySelector('.book').innerHTML
    }

    let event = new CustomEvent('pick:picked', { bubbles: true, detail: { id: book_id, html: html } })
    this.element.dispatchEvent(event)
  }

  switch(e) {
    let tab = e.target.dataset.tab

    // switch active tab
    this.element.querySelector('.tabs .is-active').classList.remove('is-active')
    this.element.querySelector('.tabs a[data-tab="'+tab+'"]').closest('li').classList.add('is-active')

    // switch tab content
    this.element.classList.remove('quick', 'nielsen')
    this.element.classList.add(tab)

    // refresh info
    let info = this.infoTarget.content.cloneNode(true)
    this.resultsTarget.innerHTML = ''
    this.resultsTarget.appendChild(info)
  }
}
