import { Controller } from "stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [ 'modal', 'content' ]

  disconnect() {
    document.querySelector('html').classList.remove('is-clipped')
  }

  open(e) {
    e.preventDefault()
    this.close()

    let modal = this.modalTarget

    if (e.target.dataset.modal) {
      modal = document.getElementById(e.target.dataset.modal)
    }

    if (modal) {
      modal.classList.add('is-active')
      document.querySelector('html').classList.add('is-clipped')

      const modal_root = e.target.closest('[data-modal-url], a')

      if (modal_root) {
        const url = modal_root.dataset.modalUrl || modal_root.href
        this.fill_modal(url)
      }
    }
  }

  fill_modal(url) {
    Rails.ajax({
      url: url,
      type: 'GET',
      success: (data) => {
        // clear existing
        if (this.hasContentTarget) { this.contentTarget.remove() }

        // reset autofocus
        if (data.querySelector("[autofocus]")) {
          document.activeElement.blur()
        }

        // append new
        let content = data.querySelector('[data-target="modal.content"]')

        if (content) {
          this.modalTarget.append(content)
        } else {
          console.log('Could not find modal content target in response')
        }
      }
    })
  }

  close() {
    let modal = document.querySelector('.modal.is-active')

    if (modal) {
      modal.classList.remove('is-active')
      document.querySelector('html').classList.remove('is-clipped')

      if (this.hasContentTarget) {
        this.contentTarget.innerHTML = ''
      }
    }
  }
}
