import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["package", "type"]

  connect() {
    this.update()
  }

  update() {
    let defaultType = undefined
    let selectedContainer = undefined
    const bespoke = this.packageTarget.selectedOptions[0].dataset.bespoke
    let voucher_types = JSON.parse(this.packageTarget.selectedOptions[0].dataset.voucherTypes || "[]")

    const selected = this.typeTargets.find((type) => { return type.checked })

    this.typeTargets.forEach((type) => {
      if (type.dataset.bespoke == undefined) { return }

      const typeContainer = type.closest(".control")

      if (voucher_types.indexOf(type.value) >= 0) {
        if (defaultType == undefined) { defaultType = type }
        typeContainer.classList.remove("hide")

      } else {
        typeContainer.classList.add("hide")
        if (type == selected) { selectedContainer = typeContainer }
      }
    })

    // don't set the default if no package selected
    if (bespoke == undefined) { return }

    // if necessary, select the default voucher type
    if (selected == undefined || selectedContainer) {
      defaultType.checked = true

      let event = new CustomEvent("change", { bubbles: true })
      defaultType.dispatchEvent(event)
    }
  }
}
