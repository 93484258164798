import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    setTimeout(this.reload, this.delay())
    this.element.remove()
  }

  reload() {
    document.location.reload()
  }

  delay() {
    return (this.element.dataset.seconds || 3) * 1000
  }
}
