import { Controller } from "stimulus"
import Rails from '@rails/ujs'
import debounce from "lodash.debounce"

export default class extends Controller {
  static targets = ['input', 'loader', 'results', 'value', 'embed', 'embedClear', 'field']

  initialize() {
    this.input = debounce(this.input, 500)
    this.token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    this.locked_open = false
  }

  // remove whitespace
  search_value() {
    let inputs = {}

    if (this.inputTargets.length > 1) {
      this.inputTargets.forEach((input) => {
        inputs[input.name] = input.value.trim()
      })
    } else {

      inputs = { quick_search: this.inputTarget.value.trim() }
    }

    return inputs
  }

  search_value_valid() {
    Object.values(this.search_value()).forEach((value) => {
      if (value.length <= 2) {
        return false
      }
    })

    return true
  }

  input(e) {
    if (this.search_value_valid()) {
      this.search()
    } else {
      this.clear_results()
    }
  }

  hide() {
    if (!this.hasResultsTarget) { return }
    this.resultsTarget.classList.add('hide')
  }

  show() {
    if (!this.hasResultsTarget) { return }
    this.resultsTarget.classList.remove('hide')
  }

  lock() {
    this.locked_open = true
  }

  unlock() {
    this.locked_open = false
  }

  interact(e) {
    if (e.keyCode == 38) { // up
      e.preventDefault()
      e.stopPropagation()
      this.up()
    } else if (e.keyCode == 40) { // down
      e.preventDefault()
      e.stopPropagation()
      this.down()
    } else if (e.keyCode == 13) { // enter
      e.preventDefault()
      this.submit_form()
    }
  }

  select(e) {
    this.change_selected(e.target)
  }

  choose(e) {
    if (this.data.get('action') == 'pick') {
      this.submit_form()
    } else {
      this.embed_selected()
    }

    this.hide()
  }

  embed_clear() {
    this.embedTarget.innerHTML = '<p>'+this.embedTarget.dataset.empty+'</p>'
    this.embedClearTarget.classList.add('hide')
    this.inputTarget.classList.remove('hide')

    let was_id = null

    if (this.selected_item) {
      was_id = this.selected_item.dataset.id
    }

    this.change_selected()

    let event = new CustomEvent('pick:cleared', { bubbles: true, detail: { id: was_id } })
    this.element.dispatchEvent(event)
  }

  up() {
    if (this.selected_item) {
      this.change_selected(this.selected_item.previousElementSibling)
    }
  }

  down() {
    if (this.selected_item && this.selected_item.nextElementSibling.dataset.id) {
      this.change_selected(this.selected_item.nextElementSibling)
    } else if (!this.selected_item) {
      // start from top
      this.change_selected(this.resultsTarget.querySelector('li'))
    }
  }

  change_selected(new_item) {
    if (this.selected_item) { this.selected_item.classList.remove('selected') }

    if (new_item) {
      new_item.classList.add('selected')
      this.selected_item = new_item
      this.set_value(new_item.dataset.id || '')
      this.ensure_selected_visible()
    } else {
      this.set_value('')
      this.selected_item = null
    }
  }

  set_value(value) {
    this.valueTarget.value = value
    let event = new CustomEvent('change')
    this.valueTarget.dispatchEvent(event)
  }

  ensure_selected_visible() {
    if (this.selected_item) {
      // locate the results dropdown
      let results_rect = this.resultsTarget.getBoundingClientRect();
      let results_viewable_area = {
        height: this.resultsTarget.clientHeight,
        width: this.resultsTarget.clientWidth
      };

      // locate the selected item
      let item_rect = this.selected_item.getBoundingClientRect();
      let item_height = this.selected_item.clientHeight
      let viewable = (item_rect.top >= results_rect.top) && (item_rect.top <= results_rect.top + results_viewable_area.height) && (item_rect.bottom <= results_rect.bottom);

      if (!viewable) {
        let new_scroll_top = (item_rect.top + this.resultsTarget.scrollTop) - results_rect.top
        let diff = new_scroll_top - this.resultsTarget.scrollTop

        if (new_scroll_top >= this.resultsTarget.scrollTop) { diff = item_height }

        // scroll by offset relative to parent
        this.resultsTarget.scrollTop += diff
      }
    }
  }

  submit_form() {
    if (this.element.tagName != "FORM") { return }

    if (this.valueTarget.value != '') {
      this.inputTarget.parentElement.classList.add('is-loading')
      Rails.fire(this.element.closest('form'), 'submit')
    }
  }

  embed_selected() {
    const book = this.selected_item.querySelector('.book-details')
    this.embedTarget.innerHTML = book.innerHTML
    this.embedClearTarget.classList.remove('hide')
    this.inputTarget.classList.add('hide')

    let event = new CustomEvent('pick:picked', { bubbles: true, detail: { id: this.selected_item.dataset.id, html: book.innerHTML } })
    this.element.dispatchEvent(event)
  }

  ignore(e) {
    e.stopPropagation()
  }

  search() {
    this.loading()

    let params = this.search_value()
    params["search_action"] = this.data.get('action')

    let options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=utf-8', 'X-CSRF-Token': this.token },
      body: JSON.stringify(params),
      credentials: 'same-origin'
    }

    fetch(this.data.get('url'), options)
      .then(response => response.text())
      .then(data => {
        this.resultsTarget.innerHTML = data
        this.resultsTarget.scrollTop = 0
        this.change_selected()
        this.loaded()

      })
      .catch(error => {
        console.log('error', error)
        this.loaded()
      })
  }

  loading() {
    if (this.hasLoaderTarget) {
      this.loaderTarget.classList.add('is-loading')
    } else {
      this.inputTarget.parentElement.classList.add('is-loading')
    }
  }

  loaded() {
    setTimeout(() => {
      if (this.hasLoaderTarget) {
        this.loaderTarget.classList.remove('is-loading')
      } else {
        this.inputTarget.parentElement.classList.remove('is-loading')
      }
    }, 250)
  }

  before(e) {
    if (!this.search_value_valid()) {
      e.preventDefault()
    }
  }

  success(e) {
    let controller = this
    const [data, status, xhr] = event.detail
    this.clear_results()

    if (data) {
      data.querySelectorAll('li').forEach(function(item){
        controller.resultsTarget.append(item)
      })
    }
  }

  clear_results() {
    this.resultsTarget.innerHTML = ''
  }
}
