import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'copy' ]

  enter(e) {
    this.item(e).dataset.tooltip = "Copy?"

    // select text of target element
    let range = document.createRange()
    range.selectNodeContents(this.item(e))

    const selection = window.getSelection()
    selection.removeAllRanges()
    selection.addRange(range)
  }

  copy(e) {
    // copy to clipboard
    document.execCommand("copy")

    // feedback
    this.item(e).dataset.tooltip = "Copied!"
    this.clearSelection()
  }

  leave(e) {
    this.clearSelection()
  }

  clearSelection(element) {
    window.getSelection().removeAllRanges()
  }

  item(e) {
    return e.target.closest('[data-action]')
  }
}
