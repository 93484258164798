import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'user', 'package', 'country', 'previous', 'usePrevious', 'voucher', 'gift', 'paid', 'discount', 'percent', 'submit' ]

  connect() {
    this.totalPrice = ""
    if (this.packageTarget.value == "") { this.disable_amounts() }
    if (!this.voucherTarget.querySelector('input:checked')) { this.disable_gift_fields() }
  }

  updated() {
    this.reset_amounts()

    if (this.packageTarget.value == "" || this.userTarget.value == "") {
      this.disable_amounts()
      return
    }
  }

  price_updated(e) {
    this.totalPrice = e.detail.price
    this.paidTarget.value = e.detail.price
    this.paid_updated()
  }

  previous_keydown(e) {
    // whitelist backspace/delete, cursor keys
    if ([8,46,37,38,39,40].indexOf(e.keyCode) >= 0) { return }

    // only allow numbers, spaces, and commas
    if (e.shiftKey || e.ctrlKey || e.metaKey || /[^0-9,\s]/.test(e.key)) { e.preventDefault() }
  }

  previous_updated() {
    const value = this.previousTarget.value.trim()

    if (value == "") {
      this.usePreviousTarget.disabled = true
      this.usePreviousTarget.checked = false
    } else {
      this.usePreviousTarget.disabled = false
    }
  }

  voucher_updated() {
    this.reset_gift_fields()
  }

  paid_updated() {
    this.normalise_amount(this.paidTarget)

    if (this.package_price() > 0) {
      this.discountTarget.value = Math.max(...[0, (this.package_price() - this.paid())]).toFixed(2)
      this.percentTarget.value = this.percent_from_discount()
    }
  }

  discount_updated(e) {
    this.normalise_amount(this.discountTarget)

    if (this.package_price() > 0) {
      this.percentTarget.value = this.percent_from_discount()
      this.paidTarget.value = Math.max(...[0, (this.package_price() - this.discount())]).toFixed(2)
    }
  }

  percent_updated(e) {
    this.normalise_percent(this.percentTarget)

    if (this.package_price() > 0) {
      this.discountTarget.value = this.discount_from_percent()
      this.paidTarget.value = Math.max(...[0, (this.package_price() - this.discount())]).toFixed(2)
    }
  }

  selected_package() {
    return this.packageTarget.selectedOptions[0]
  }

  // full_price() {
  //   return Number(this.selected_package().dataset.price)
  // }

  // renewal_price() {
  //   return Number(this.selected_package().dataset.renewal)
  // }

  package_price() {
    // automatic renewal price:
    // return this.renewalTarget.checked ? this.renewal_price() : this.full_price()
    return this.totalPrice
  }

  paid() {
    return Number(this.paidTarget.value)
  }

  discount() {
    return Number(this.discountTarget.value)
  }

  percent() {
    return Number(this.percentTarget.value)
  }

  normalise_amount(field) {
    const value = field.value.trim()

    // special case leading decimal
    if (value == '.') { value = "0." }

    const amount = (value.match(/(\.\d{0,2}|\d+\.\d{0,2}|\d+).*/) || [])[1]

    if (amount) {
      field.value = Math.min(amount, this.package_price())
    } else {
      field.value = this.percent_from_discount()
    }
  }

  percent_from_discount() {
    return ((this.discount() / this.package_price()) * 100).toFixed(2)
  }

  discount_from_percent() {
    return (this.package_price() * (Number(this.percent()) / 100)).toFixed(2)
  }

  normalise_percent(field) {
    const value = field.value.trim()
    const percent = (value.match(/(\d+\.\d{0,2}|\d+).*/) || [])[1]

    if (percent) {
      field.value = Math.min(Math.max(percent, 0), 100)
    } else {
      field.value = ""
    }
  }

  reset_amounts() {
    // this.paidTarget.value = this.totalPrice
    // this.discountTarget.value = ""
    // this.percentTarget.value = ""

    this.paidTarget.disabled = false
    this.discountTarget.disabled = false
    this.percentTarget.disabled = false
    this.submitTarget.disabled = false
  }

  disable_amounts() {
    this.paidTarget.disabled = true
    this.discountTarget.disabled = true
    this.percentTarget.disabled = true
    this.submitTarget.disabled = true
  }

  reset_gift_fields() {
    this.giftTargets.forEach((field) => {
      field.disabled = false
    })
  }

  disable_gift_fields() {
    this.giftTargets.forEach((field) => {
      field.disabled = true
    })
  }
}
