import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["operand", "result"]

  connect() {
    this.operator = this.element.dataset.mathsOperator
    this.math()
  }

  math() {
    if (this.operator == "+") {
      const operands = this.operandTargets.map((element) => parseFloat(element.value))
      const result = operands.reduce((accumulator, operand) => accumulator + operand)

      this.resultTarget.value = Number.parseFloat(result).toFixed(2)
    } else {
      console.log("Unknown maths operator:", this.operator)
    }
  }
}
