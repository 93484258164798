import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'tab', 'content' ]

  connect() {
    if (this.data.has("equalise")) { this.set_min_height() }
  }

  switch(e) {
    let tab_id = e.target.closest('[data-tab]').dataset.tab

    this.tabTargets.forEach((tab) => {
      if (tab.dataset.tab == tab_id) {
        tab.classList.add('is-active')
      } else {
        tab.classList.remove('is-active')
      }
    })

    this.contentTargets.forEach((content) => {
      if (content.dataset.tab == tab_id) {
        content.classList.remove('hide')
      } else {
        content.classList.add('hide')
      }
    })
  }

  set_min_height() {
    let min_height = ''

    // determine the minimum height
    this.contentTargets.forEach((content) => {
      if (content.dataset.ignoreEqualise != undefined) {
        return
      }

      let hidden = content.classList.contains('hide')
      if (hidden) { content.classList.remove('hide') }

      let height = content.offsetHeight
      if (height > min_height) { min_height = height }

      if (hidden) { content.classList.add('hide') }
    })

    // apply to all tabs
    this.contentTargets.forEach((content) => {
      content.style.minHeight = min_height + "px"
    })
  }
}
