import { Controller } from "stimulus"
import { createConsumer } from "@rails/actioncable"
import CableReady from 'cable_ready'

export default class extends Controller {
  static targets = []

  initialize() {
    let debug = false
    this.consumer = createConsumer();
    this.consumer.subscriptions.create(
      { channel: this.data.get('channel')+'Channel', id: this.data.get('id') },
      {
        initialized() { if (debug) { console.log('initialized') } },
        received(data) {
          if (debug) { console.log('received', data) }

          if (data.cableReady) {
            if (debug) { console.log('applying') }
            CableReady.perform(data.operations)
          }
        },
        connected() { if (debug) { console.log('connected') } },
        disconnected() { if (debug) { console.log('disconnected') } },
        rejected() { if (debug) { console.log('rejected') } }
      }
    );
  }

  disconnect() {
    this.consumer.subscriptions.consumer.disconnect()
  }
}
