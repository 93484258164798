import { Controller } from "stimulus"
import {loadStripe} from '@stripe/stripe-js';

export default class extends Controller {
  static targets = ["payment", "button", "error"]

  connect() {
    this.buttonTarget.classList.add("hide")
    this.setupStripe()
  }

  async setupStripe() {
    this.stripe = await loadStripe(this.element.dataset.stripeKey)

    const options = {
      clientSecret: this.element.dataset.clientSecret,
      // Fully customizable with appearance API.
      appearance: {/*...*/},
    }

    // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 3
    this.stripeElements = this.stripe.elements(options)

    // Create and mount the Payment Element
    const paymentElement = this.stripeElements.create("payment")
    paymentElement.on("ready", () => this.buttonTarget.classList.remove("hide"))
    paymentElement.mount(this.paymentTarget)
  }

  async submit(e) {
    e.preventDefault()

    this.setError(false)

    const elements = this.stripeElements

    try {
      const {error} = await this.stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: this.element.dataset.returnUrl,
        }
      })

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        this.setError(error)
      } else {
        this.setError(false)
      }
    } catch {
      const {error} = await this.stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: this.element.dataset.returnUrl,
        }
      })

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        this.setError(error)
      } else {
        this.setError(false)
      }
    }
  }

  setError(error) {
    if (error) {
      this.errorTarget.querySelector(".message-body").textContent = error.message
      this.errorTarget.classList.remove("hide")
      this.buttonTarget.disabled = true
    } else {
      this.errorTarget.classList.add("hide")
      this.buttonTarget.disabled = false
    }
  }
}
