import { Controller } from "stimulus"
import { Pagy } from '../vendor/pagy.js.erb'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ 'filters', 'results', 'submit', 'sortBy', 'sortDir' ]

  update(e) {
    const [data, status, xhr] = e.detail;

    // this.update_history(xhr) # inconsistent behaviour on back navigation (Turbolinks?)
    this.update_content(data)
  }

  loading(e) {
    this.resultsTarget.classList.add('is-loading')
  }

  loaded(e) {
    this.resultsTarget.classList.remove('is-loading')
  }

  load_all(e) {
    e.preventDefault()
    e.target.classList.add('is-loading')
    Rails.fire(this.submitTarget.closest('form'), 'submit')
  }

  reset(e) {
    location.href = this.submitTarget.closest('form').action
  }

  top(e) {
    scroll(0,0)
  }

  sort(e) {
    e.preventDefault()

    let link = e.target
    this.sortByTarget.value = link.dataset.sortBy || ''
    this.sortDirTarget.value = link.dataset.sortDir || ''
    Rails.fire(this.submitTarget.closest('form'), 'submit')
  }

  update_history(xhr) {
    history.pushState(null, '', xhr.responseURL)
  }

  update_content(data) {
    if (data.filters) {
      this.filtersTarget.innerHTML = data.filters
    }

    if (data.results) {
      this.resultsTarget.innerHTML = data.results
      window.Pagy.init()
    }
  }
}
