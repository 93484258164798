import { dom, library } from '@fortawesome/fontawesome-svg-core'
import { faAngleDown } from '@fortawesome/pro-light-svg-icons/faAngleDown'
import { faAngleUp } from '@fortawesome/pro-light-svg-icons/faAngleUp'
import { faArrowAltDown } from '@fortawesome/pro-light-svg-icons/faArrowAltDown'
import { faArrowAltLeft } from '@fortawesome/pro-light-svg-icons/faArrowAltLeft'
import { faArrowAltRight } from '@fortawesome/pro-light-svg-icons/faArrowAltRight'
import { faArrowAltUp } from '@fortawesome/pro-light-svg-icons/faArrowAltUp'
import { faArrowsAltH } from '@fortawesome/pro-light-svg-icons/faArrowsAltH'
import { faAsterisk } from '@fortawesome/pro-light-svg-icons/faAsterisk'
import { faAt } from '@fortawesome/pro-light-svg-icons/faAt'
import { faBan } from '@fortawesome/pro-light-svg-icons/faBan'
import { faBarcodeRead } from '@fortawesome/pro-light-svg-icons/faBarcodeRead'
import { faBook } from '@fortawesome/pro-light-svg-icons/faBook'
import { faBookmark } from '@fortawesome/pro-light-svg-icons/faBookmark'
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle'
import { faCog } from '@fortawesome/pro-light-svg-icons/faCog'
import { faComment } from '@fortawesome/pro-light-svg-icons/faComment'
import { faCommentCheck } from '@fortawesome/pro-light-svg-icons/faCommentCheck'
import { faCommentDots } from '@fortawesome/pro-light-svg-icons/faCommentDots'
import { faCommentExclamation } from '@fortawesome/pro-light-svg-icons/faCommentExclamation'
import { faComments } from '@fortawesome/pro-light-svg-icons/faComments'
import { faDownload } from '@fortawesome/pro-light-svg-icons/faDownload'
import { faEdit } from '@fortawesome/pro-light-svg-icons/faEdit'
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle'
import { faExternalLinkAlt } from '@fortawesome/pro-light-svg-icons/faExternalLinkAlt'
import { faG } from "@fortawesome/pro-light-svg-icons/faG"
import { faGift } from "@fortawesome/pro-light-svg-icons/faGift"
import { faHashtag } from "@fortawesome/pro-light-svg-icons/faHashtag"
import { faHomeLg } from '@fortawesome/pro-light-svg-icons/faHomeLg'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle'
import { faListAlt } from '@fortawesome/pro-light-svg-icons/faListAlt'
import { faLockAlt } from '@fortawesome/pro-light-svg-icons/faLockAlt'
import { faPenSquare } from '@fortawesome/pro-light-svg-icons/faPenSquare'
import { faPercent } from '@fortawesome/pro-light-svg-icons/faPercent'
import { faPhoneRotary } from '@fortawesome/pro-light-svg-icons/faPhoneRotary'
import { faPoundSign } from '@fortawesome/pro-light-svg-icons/faPoundSign'
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons/faQuestionCircle'
import { faRecycle } from '@fortawesome/pro-light-svg-icons/faRecycle'
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch'
import { faSlash } from "@fortawesome/free-solid-svg-icons/faSlash"
import { faSquare } from '@fortawesome/pro-light-svg-icons/faSquare'
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons/faTimesCircle'
import { faTrash } from '@fortawesome/pro-light-svg-icons/faTrash'
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser'
import { faUpload } from '@fortawesome/pro-light-svg-icons/faUpload'

library.add(
  faAngleDown,
  faAngleUp,
  faArrowAltDown,
  faArrowAltLeft,
  faArrowAltRight,
  faArrowAltUp,
  faArrowsAltH,
  faAsterisk,
  faAt,
  faBan,
  faBarcodeRead,
  faBook,
  faBookmark,
  faCheckCircle,
  faCog,
  faComment,
  faCommentCheck,
  faCommentDots,
  faCommentExclamation,
  faComments,
  faDownload,
  faEdit,
  faExclamationTriangle,
  faExternalLinkAlt,
  faG,
  faGift,
  faHashtag,
  faHomeLg,
  faInfoCircle,
  faListAlt,
  faLockAlt,
  faPenSquare,
  faPercent,
  faPhoneRotary,
  faPoundSign,
  faQuestionCircle,
  faRecycle,
  faSearch,
  faSlash,
  faSquare,
  faTimesCircle,
  faTrash,
  faUser,
  faUpload,
)

dom.i2svg()
dom.watch()
