import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import debounce from "lodash.debounce"

export default class extends Controller {
  static targets = [ "field", "items", "item", "none" ]

  connect() {
    this.filter = debounce(this.filter, 250)

    // HTML attribute is insufficient
    this.focusSearchField()
  }

  focusSearchField() {
    this.fieldTarget.focus()
  }

  loading() {
    this.fieldTarget.closest('.control').classList.add('is-loading')
  }

  loaded() {
    this.fieldTarget.closest('.control').classList.remove('is-loading')
  }

  filter(e) {
    // don't trigger events by other forms containing this one
    e.stopPropagation()

    let search_term = this.fieldTarget.value.trim().toLowerCase()

    this.itemsTarget.classList.add('hide')
    this.noneTarget.classList.add('hide')

    if (search_term == "") {
      // all
      this.itemTargets.forEach((item) => { item.classList.remove('hide') })
      this.itemsTarget.classList.remove('hide')

    } else if (this.itemsTarget.querySelector('[data-text*="'+search_term+'"]')) {
      // at least one
      this.itemTargets.forEach((item) => {
        if (item.dataset.text.indexOf(search_term) >= 0) {
          item.classList.remove('hide')
        } else {
          item.classList.add('hide')
        }
      })

      this.itemsTarget.classList.remove('hide')
    } else {
      // none
      this.noneTarget.classList.remove('hide')
    }

    this.loaded()
  }
}
