import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'action', 'clear', 'original', 'postage', 'processNow', 'reason', 'replacement', 'replacementId', 'search', 'submit', 'user' ]

  connect() {
    if (this.hasReplacementIdTarget) {
      this.updateAction()
      this.updateForm()
    }
  }

  has_reason() {
    return this.hasFixedReasonTarget || this.reasonTarget.querySelector('input:checked')
  }

  has_action() {
    return this.actionTarget.dataset.replacementAction || this.actionTarget.querySelector('input:checked')
  }

  has_user() {
    return Number(this.userTarget.value) > 0
  }

  get_action() {
    const selected = this.actionTarget.querySelector('input:checked')
    if (selected) { return selected.value }
  }

  updateAction() {
    const has_replacement = parseInt(this.replacementIdTarget.value) > 0

    this.actionTarget.querySelectorAll('input[type="radio"]').forEach((element) => {
      const disabled = (element.dataset.book == "true") != has_replacement
      const label = element.closest('label')

      element.disabled = disabled

      if (disabled) {
        label.classList.add('disabled')
        element.checked = false
      } else {
        label.classList.remove('disabled')
      }
    })
  }

  updateForm() {
    if (this.has_reason() && this.has_action() && this.has_user()) {
      this.processNowTarget.value = "1"
      this.submitTarget.disabled = false
    } else {
      this.processNowTarget.value = "0"
      this.submitTarget.disabled = true
    }

    // optional postage value
    if (this.get_action() == "post_from_shop") {
      this.postageTarget.classList.remove('hide')
    } else {
      this.postageTarget.classList.add('hide')
    }
  }

  modal_picked(e) {
    this.replacementTarget.innerHTML = e.detail.html
    this.replacementIdTarget.value = e.detail.id

    this.clearTarget.classList.remove('hide')
    this.searchTarget.classList.add('hide')

    this.updateAction()
    this.updateForm()
  }

  same_pick() {
    this.replacementTarget.innerHTML = this.originalTarget.innerHTML
    this.replacementIdTarget.value = this.data.get('book')

    this.clearTarget.classList.remove('hide')
    this.searchTarget.classList.add('hide')

    this.updateAction()
    this.updateForm()
  }
}
