import { Controller } from "stimulus"
import { createConsumer } from "@rails/actioncable"

export default class extends Controller {
  static targets = [ 'info' ]

  initialize() {
    this.generating = false
  }

  preview(e) {
    const form_data = new URLSearchParams(new FormData(this.element))

    fetch(this.element.dataset.preview, { method: 'post', body: form_data})
      .then(response => response.json())
      .then(data => {
        this.infoTarget.innerHTML = data.rows
      })
  }

  queued(e) {
    this.pending()
    this.generating = true // allow percent updates

    const [data, status, xhr] = e.detail
    this.connectRenderer(data)
  }

  process(data) {
    if ("percent" in data) {
      this.progress(data.percent)
    } else if ("url" in data) {
      this.redirect(data.url)
    } else if ("empty" in data) {
      this.empty()
    }
  }

  empty() {
    this.infoTarget.innerHTML = "Empty file!"
    this.stop()
  }

  pending() {
    this.infoTarget.innerHTML = "Please wait..."
  }

  progress(percent) {
    if (!this.generating) { return }

    if (percent == 100) {
      this.pending()
    } else {
      this.infoTarget.innerHTML = percent+"%"
    }
  }

  redirect(url) {
    this.stop()

    // redirect to download URL
    window.location.href = url
  }

  stop() {
    // block further percent updates
    this.generating = false

    // disconnect from cable
    this.disconnectRenderer()

    // stop the loading spinner
    let event = new CustomEvent('report:complete')
    this.element.dispatchEvent(event)

    // reset the preview in a bit
    setTimeout(() => { this.preview() }, 1000)
  }

  connectRenderer(data) {
    let controller = this
    this.consumer = createConsumer()
    this.consumer.subscriptions.create(
      { channel: 'ReportChannel', id: data.id },
      { received(json) { controller.process(json) } }
    )
  }

  disconnectRenderer() {
    this.consumer.subscriptions.consumer.disconnect()
  }

  disconnect() {
    if (this.consumer) { this.disconnectRenderer() }
  }
}
