import { Controller } from "stimulus"
import Rails from '@rails/ujs'
import debounce from "lodash.debounce"

export default class extends Controller {
  static targets = [ 'submit' ]

  initialize() {
    const autosave_delay = this.element.dataset.formAutosaveDelayValue || 500
    this.autosave = debounce(this.autosave, parseInt(autosave_delay))
  }

  connect() {
    if (this.hasSubmitTarget) {
      if (this.submitTarget.dataset.saved) {
        this.saved()
      } else if (this.submitTarget.dataset.errored) {
        this.errored()
      }
    }

    if ("submitFormOnLoad" in this.element.dataset) {
      this.submit()
    }
  }

  submit(e) {
    Rails.fire(this.element, 'submit');
  }

  loading() {
    if (!this.hasSubmitTarget) { return }

    this.submitTarget.classList.add('is-loading')
    this.submitTarget.setAttribute('disabled', 'disabled')
  }

  loaded(immediate=false) {
    if (!this.hasSubmitTarget) { return }

    if (immediate) {
      this.submitTarget.classList.remove('is-loading')
      this.submitTarget.removeAttribute('disabled')
    } else {
      setTimeout(()=>{
        this.submitTarget.classList.remove('is-loading')
        this.submitTarget.removeAttribute('disabled')
      }, 150)
    }
  }

  saved() {
    if (!this.hasSubmitTarget) { return }
    if (!this.submitTarget.dataset.saved) { return }

    this.submitTarget.classList.add('is-saved')
    setTimeout(()=>{ this.submitTarget.classList.remove('is-saved') }, 1500)
    this.submitTarget.removeAttribute('data-saved')
  }

  errored(failed=false) {
    if (!this.hasSubmitTarget) { return }

    this.loaded(true)

    let error_class = 'is-errored'
    if (failed) { error_class = 'is-failed' } else if (!this.submitTarget.dataset.errored) { return }

    this.submitTarget.classList.add(error_class)
    setTimeout(()=>{ this.submitTarget.classList.remove(error_class) }, 1500)
    this.submitTarget.removeAttribute('data-errored')
  }

  // replace entire controller with new one in error state using HTML supplied in response
  error(e) {
    // don't trigger events by other forms containing this one
    e.stopPropagation()

    const [data, status, xhr] = event.detail;
    if (data instanceof Node) {
      // replace the form
      let new_element = data.querySelector('[data-controller~="form"]');
      let replace_element = this.element.closest('form')
      if (replace_element) {
        replace_element.parentNode.replaceChild(new_element, replace_element)
      } else {
        this.errored(true)
      }

    } else {
      this.errored(true)
    }
  }

  success(e) {
    // don't trigger events by other forms containing this one
    e.stopPropagation()

    const [data, status, xhr] = event.detail;
    if (data instanceof Node) {
      // replace the form
      let new_element = data.querySelector('[data-controller~="form"]');
      let replace_element = this.element.closest('form')
      if (replace_element) {
        setTimeout(()=>{
          replace_element.parentNode.replaceChild(new_element, replace_element)
        }, 150)

      } else {
        this.errored(true)
      }

    } else {
      this.saved()
      this.element.querySelectorAll('.input.is-danger').forEach((element) => { element.classList.remove('is-danger') })
      this.element.querySelectorAll('.help.is-danger').forEach((element) => { element.remove() })
    }
  }

  autosave(e) {
    this.submit()
  }

  autosavePending(e) {
    let field = e.target
    let control = e.target.closest('.control')

    field.classList.add('is-warning', 'is-autosaving')
    field.classList.remove('is-success', 'is-danger', 'is-autosaved')
    if (control) { control.classList.add('is-loading') }
  }

  autosaveSuccess(e) {
    this.element.querySelectorAll('.is-autosaving').forEach((element) => {
      element.classList.add('is-success', 'is-autosaved')
      element.classList.remove('is-warning', 'is-danger', 'is-autosaving')
    })
  }

  autosaveError(e) {
    this.element.querySelectorAll('.is-autosaving').forEach((element) => {
      element.classList.add('is-danger')
      element.classList.remove('is-warning', 'is-success', 'is-autosaving', 'is-autosaved')
    })
  }

  autosaveComplete(e) {
    this.element.querySelectorAll('.is-loading').forEach((element) => {
      element.classList.remove('is-loading')
    })
  }
}
