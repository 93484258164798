import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "name" ]

  connect() {
    this.inputTarget = this.element.querySelector('input[type="file"]')
  }

  update(e) {
    if (!this.hasNameTarget) { return }

    if (this.inputTarget.files.length > 0) {
      this.nameTarget.textContent = this.inputTarget.files[0].name
    } else {
      this.nameTarget.textContent = "No file selected"
    }
  }
}
