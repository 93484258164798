import { Controller } from "stimulus"

/*
  Actions:
    hide - hide all the target items
    show - show all the target items
    toggle - invert the visibility class of all target items (default: hide)
    switch - show only the target items with a switch attribute matching/containing
             the value of the triggering element (eg. a radio button)
 */
export default class extends Controller {
  static targets = [ 'togglee' ]

  hide(e) {
    if (!e.target.checked && !e.target.selected) { return; }

    this.toggleeTargets.forEach((item) => {
      this.hide_item(item)
    });
  }

  show(e) {
    if (!e.target.checked && !e.target.selected) { return; }

    this.toggleeTargets.forEach((item) => {
      this.show_item(item)
    });
  }

  toggle(e) {
    this.toggleeTargets.forEach((item) => {
      this.toggle_item(item)
    });
  }

  switch(e) {
    this.toggleeTargets.forEach((item) => {
      if ("switch" in item.dataset && item.dataset.switch.split(" ").includes(e.target.value)) {
        this.show_item(item)
      } else {
        this.hide_item(item)
      }
    })
  }

  toggle_class() {
    return this.data.get('class') || 'hide'
  }

  toggle_item(item) {
    item.classList.toggle(this.toggle_class());

    if ("togglerFocus" in item.dataset && !item.classList.contains(this.toggle_class())) {
      this.focus_item(item)

    }
  }

  show_item(item) {
    item.classList.remove(this.toggle_class());

    if ('togglerFocus' in item.dataset) {
      this.focus_item(item)
    }
  }

  hide_item(item) {
    item.classList.add(this.toggle_class());
  }

  focus_item(item) {
    let input = item.querySelector('input[type="text"], textarea')

    if (input) {
      input.focus()
      const temp_val = input.value
      input.value = ''
      input.value = temp_val
      input.scrollTop = 99999
    }
  }
}
