import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "field" ]

  connect() {
    if (this.hasFieldTarget) { this.update() }
  }

  update() {
    const has_value = this.fieldTargets.some((field) => { return field.value.trim().length > 0 })

    if (has_value) {
      this.fieldTargets.forEach((field) => { if (field.value.trim().length == 0) { field.disabled = true } })
    } else {
      this.fieldTargets.forEach((field) => { field.disabled = false })
    }
  }
}
