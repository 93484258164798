import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import debounce from "lodash.debounce"

export default class extends Controller {
  static targets = ["field", "results"]

  connect() {
    // HTML attribute is insufficient
    this.focusSearchField()
  }

  focusSearchField() {
    if (this.hasFieldTarget) {
      this.fieldTarget.focus()
    }
  }

  update(e) {
    // don't trigger events by other forms containing this one
    e.stopPropagation()

    const [data, status, xhr] = event.detail;

    if (data instanceof Node) {
      // replace the form
      let results = data.querySelector(".results");
      let controller = this

      setTimeout(()=>{
        if (controller.hasResultsTarget) {
          controller.resultsTarget.innerHTML = ""
          controller.resultsTarget.appendChild(results)

          // ensure searching can continue
          controller.focusSearchField()
        }
      }, 150)
    }
  }
}
